<template lang="pug">
.app
  van-nav-bar(title="登 录")
  div
    div(class="input" style="background-color: #FFFFFF;display: flex;align-items: center;justify-content: space-around;font-size: 16px;    box-sizing: border-box;box-shadow: 0 0.5em 2em -0.7em rgb(64 90 163 / 15%);" id='ln')
      van-radio-group(v-model="radio" direction="horizontal")
        van-radio(name="1") 中 文
        van-radio(name="2") English
    div(class="content" style="width: 100%;")
      el-form(:model="loginData" ref="loginPasswordForm" :rules="rules")
        el-form-item(prop="phone")
          el-input(v-model="loginData.phone" placeholder="请输入手机号码" style="width: 80%")
        el-form-item
          el-button(type="primary" style="margin-top: 30px" @click="login('account')") 登    录

</template>

<script>
export default {
  name: 'login',
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      {
        name: 'viewport',
        content:
          'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1',
      },
    ],
  },
  data () {
    return {
      radio: '1',
      loginData: {
        phone: '',
      },
      rules: {
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { min: 11, message: '手机号格式不正确', trigger: 'blur' },
        ],
      },
    }
  },
  mounted () {
    console.log('mounted')
    if (this.$route.query.type && this.$route.query.type === '401') {
      this.$u.message({
        message: 'authorized failed! you need login again.',
        type: 'error',
      })
    }
  },
  methods: {
    login (provider) {
      const loginData = this.loginData
      this.$refs.loginPasswordForm.validate((valid) => {
        if (valid) {
          this.login2(loginData)
        }
      })
    },
    login2 (data) {
      this.loginLoading = true
      this.$api
        .login(data)
        .then((res) => {
          if (res.status === 200) {
            console.log('user login succeed')
            console.log(res.data)
            const token = res.data.token
            const user = res.data.user
            // const team = res.data.default_team;

            this.$s.commit('setToken', token)
            this.$s.commit('setProfile', user)
            // this.$s.commit("setTeam", team);
            this.$router.push({ name: 'home' })

            // if (res.data.default_team !== undefined) {
            //  console.log("login secceed and reroute");
            //  console.log(this.$route.query);
            //  if (this.$route.query.redirect) {
            //    this.$router.push({
            //      path: decodeURIComponent(this.$route.query.redirect),
            //    });
            //  } else {
            //    this.$router.push({ name: "home" });
            //  }
            // } else {
            //  console.log("user lack team");
            //  this.showLackTeam = true;
            // }
          }
          this.loginLoading = false
        })
        .catch(() => {
          this.loginLoading = false
        })
    },
    getPhoneCode (phone) {
      this.$api.getPhoneCode({ phone: phone }).then((res) => {
        if (res.status === 200) {
          this.phoneCodeAgainDurationSec = 60
          var timer = setInterval(() => {
            this.phoneCodeAgainDurationSec -= 1
            if (this.phoneCodeAgainDurationSec === 0) {
              clearInterval(timer)
            }
          }, 1000)
        }
      })
    },
    initTeam () {
      this.$api.initTeam(this.initTeamData).then((res) => {
        if (res.status === 200) {
          // need to login again
          // localStorage.removeItem("token");
          if (window.location.host === 'localhost:8080') {
            // this.$cookies.remove('token')
          } else {
            // this.$cookies.remove('token', null, 'kuaida.d.douwa.tech')
          }
          this.$router.push({ name: 'home' })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.loading-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #aedff8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.content {
  width: 100%;
  margin-top: 40%;
  text-align: center;
}
.input {
  height: 37px;
  line-height: 37px;
  border: 0px;
  color: #333333;
  font-size: 16px;
  background-color: #efefef;
}

::v-deep .el-form-item__error {
  width: 80%;
}
</style>
