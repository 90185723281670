<template lang="pug">
.auth
  router-view
</template>

<script>
export default {
  data () {
    return {}
  },
  mounted () {},
  methods: {},
}
</script>

<style lang="stylus" scoped></style>
